import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "leaflet/dist/leaflet.css";
import "assets/css/index.css";

import "swiper/css/swiper.min.css";
import "aos/dist/aos.css";

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="shortcut icon" href="/assets/img/favicon.png" />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://thecenterspace.co" />
        <meta
          property="og:image"
          content="http://thecenterspace.co/frontend/src/assets/images/metatagpicture.png"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://thecenterspace.co/" />
        <meta
          property="twitter:image"
          content="http://thecenterspace.co/frontend/src/assets/images/metatagpicture.png"
        />
        <meta
          name="google-site-verification"
          content="OgoKVfz9_2Y0BTPBc9Ldoc5TnihntkUdWlARj-Pitwc"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap"
          rel="stylesheet"
        />
        <script
          src="https://kit.fontawesome.com/4c273e6d43.js"
          crossOrigin="anonymous"
        ></script>
      </Helmet>
      {props.children}
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node
};
