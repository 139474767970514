/* eslint-disable import/prefer-default-export, react/prop-types */
import React from "react";
import TopLayout from "./TopLayout";

export const wrapRootElement = ({ element }) => {
  return <TopLayout>{element}</TopLayout>;
};

export const onInitialClientRender = () => {
  if ("scrollRestoration" in window.history) {
    window.history.scrollRestoration = "manual";
  }
  window.scrollTo({ top: 0, behavior: "smooth" }); // Always scroll to top on refresh
};
